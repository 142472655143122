// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-js": () => import("/opt/build/repo/src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---cache-gatsby-mdx-mdx-wrappers-dir-d-3-bd-3-cdc-5-c-7-e-7-b-6-bd-592-af-65-f-9-cc-7-c-8-c-scope-hash-3010-b-3-badc-54-a-9-dfa-4-a-4-c-80-e-419-a-41-b-2-js": () => import("/opt/build/repo/.cache/gatsby-mdx/mdx-wrappers-dir/d3bd3cdc5c7e7b6bd592af65f9cc7c8c--scope-hash-3010b3badc54a9dfa4a4c80e419a41b2.js" /* webpackChunkName: "component---cache-gatsby-mdx-mdx-wrappers-dir-d-3-bd-3-cdc-5-c-7-e-7-b-6-bd-592-af-65-f-9-cc-7-c-8-c-scope-hash-3010-b-3-badc-54-a-9-dfa-4-a-4-c-80-e-419-a-41-b-2-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-js": () => import("/opt/build/repo/src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

